<template>
  <div>

    <v-card>
      <v-card-title>Kończące się pakiety</v-card-title>

      <v-card-text v-if="packages.length">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Klient
                </th>
                <th class="text-left">
                  Nazwa pakietu
                </th>
                <th class="text-left">
                  Termin
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in packages"
                :key="item.id"
                @click="$router.push('/pakiet-zobacz/'+item.id)"
                class="table_pointer"
              >
                <td>{{item.assign_user_name}}</td>
                <td>
                  <v-btn outlined x-small depressed>{{item.category_name}}</v-btn>
                </td>
                <td>{{ item.end_data }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text v-else class="text-center">
        Brak kończących się pakietów
      </v-card-text>

    </v-card>
    
  </div>
</template>

<script>
export default {
  props: {
    packages: Array,
  },
  data: () => ({
  }),
  methods: {
    
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
</style>