<template>
  <div>

    <v-card>
      <v-card-title>Projekty</v-card-title>

      <div v-if="projects.length">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 60%">
                  Nazwa projektu
                </th>
                <th>
                  Postęp
                </th>
                <th>
                  Dni do końca
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in projects"
                :key="item.id"
                @click="projectClick(item.id)"
                class="table_pointer"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-progress-linear
                    color="primary"
                    buffer-value="0"
                    :value="item.progress"
                    stream
                    height="15"
                  >{{item.progress}} %</v-progress-linear>
                </td>
                <td>{{ item.finish }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div v-else class="text-center">
        Brak trwających projektów
      </div>

    </v-card>
    
  </div>
</template>

<script>
export default {
  props: {
    projects: Array,
  },
  data: () => ({

  }),
  methods: {
    projectClick(value){
      this.$router.push('/projekt/'+value);
    },
  },
  mounted(){
    
  }
};
</script>

<style lang="scss" scoped>
</style>