<template>
  <div class="home">
    
    <!-- region [success] Jeżeli jest adminem -->
    <v-row v-if="!this.$store.state.loggedUserRole">
      <!-- <v-col cols="12" md="5">
        <Notifications 
          :notifications="notifications"
        />
      </v-col> -->
      <v-col cols="12">
        <Invoices 
          :invoices="invoices"
        />
      </v-col>

      <v-col cols="12" md="7">
        <Projects 
          :projects="projects"
        />
      </v-col>

      <v-col cols="12" md="5">
        <Packages 
          :packages="packages"
        />
      </v-col>
    </v-row>
    <!-- endregion -->
    <!-- region [warning] Jeżeli jest klientem -->
    <v-row v-else-if="this.$store.state.loggedUserRole==3">
      <!-- <v-col cols="12" md="5">
        <Notifications 
          :notifications="notifications"
        />
      </v-col> -->
      <v-col cols="12">
        <Invoices 
          :invoices="invoices"
        />
      </v-col>
    </v-row>
    <!-- endregion -->

    <!-- <Chart
      :costs="40"
      :earnings="60"
    /> -->

  </div>
</template>

<script>
// import Chart from '@/components/Projects/chart.vue';

// import Notifications from '@/components/Dashboard/Notifications.vue';
import Invoices from '@/components/Dashboard/Invoices.vue';
import Projects from '@/components/Dashboard/Projects.vue';
import Packages from '@/components/Dashboard/Packages.vue';

export default {
  // #TODO zrobic przechodzenie w podstrony po kliknieciu w notyfikacje
  // #TODO Wykres przychodow miesieczny - na dashboard zamiast faktur (przesunac)
  components: {
    // Notifications,
    Invoices,
    Projects,
    Packages,
    // Chart
  },
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      }
    ],
    
    packages: [],
    projects: [],
    invoices: [],
    // notifications: []
  }),
  methods: {
    
    
  },
  mounted(){
    console.log(this.$store.state.permissions)

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/dash', data: {}, method: 'GET' })
      .then(resp => {
        // this.list = resp.data.invoice;
        // this.notifications = resp.data.notify;
        this.invoices = resp.data.invoice;
        this.projects = resp.data.projects;
        this.packages = resp.data.packages;
        // this.packages = resp.data.packages;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss">
.table_pointer {
  cursor: pointer;
}
// .card_content {
//   .v-card__text {
//     min-height: 300px;
//     &.centered {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }
</style>