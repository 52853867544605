<template>
  <div>

    <v-card>
      <v-card-title>Faktury do opłacenia</v-card-title>
      <v-card-text v-if="invoices.length">
        
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Nazwa faktury
                </th>
                <th>
                  Termin
                </th>
                <th>
                  Klient
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in invoices"
                :key="item.id"
                @click="openInvoice(item.id)"
                class="table_pointer"
              >
                <td>{{ item.number_invoice }}</td>
                <td>{{ item.date_invoice }}</td>
                <td>{{ item.client }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>

      <v-card-text v-else class="text-center">
        Wszystkie faktury są opłacone
      </v-card-text>

    </v-card>
    
  </div>
</template>

<script>
export default {
  props: {
    invoices: Array,
  },
  data: () => ({
  }),
  methods: {
    openInvoice(e){
      this.$router.push('/faktura/zobacz/'+e.id);
    }
  },
  mounted(){
    
  }
};
</script>

<style lang="scss" scoped>
</style>