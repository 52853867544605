import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=308c5480&scoped=true&"
import script from "./Projects.vue?vue&type=script&lang=js&"
export * from "./Projects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308c5480",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardTitle,VProgressLinear,VSimpleTable})
